


















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import LineTimer from '@/components/common/LineTimer.vue'
import Chat from '@/components/common/Chat.vue'
import isOS from '@/helper/detectOs'
import Modal from '@/components/common/Modal.vue'

@Component({
  components: {
    Chat,
    Header,
    Footer,
    LineTimer,
    Modal
  }
})
export default class App extends Vue {
  private timerBarVisible = false
  private calsss = 'lang_'

  private isSubmitResultTimedOutInformationModalOpen = false

  private mounted (): void {
    if (window.innerWidth < 767 && isOS()) {
      window.scrollTo(0, 1)
    }
    this.calsss = this.calsss + this.$store.getters['walkthrough/getLang']

    this
      .$store
      .watch(
        (state, getters) => getters['timer/timerBarVisible'],
        (timerBarVisible) => { this.timerBarVisible = timerBarVisible }
      )

    this
      .$store
      .watch(
        (state, getters) => getters['walkthrough/getLang'],
        (getLang:string) => {
          console.log('getLang', getLang)
          this.calsss = this.calsss + getLang
        }
      )

    this
      .$store
      .watch(
        (state, getters) => getters['walkthrough/submitResultTimedOut'],
        (submitResultTimedOut) => {
          if (submitResultTimedOut) {
            this.isSubmitResultTimedOutInformationModalOpen = true
          }
        }
      )
  }

  private goToWelcomeScreen (): void {
    this
      .$store
      .dispatch('walkthrough/clearResults')
      .then(() => {
        this.isSubmitResultTimedOutInformationModalOpen = false
        this.goToEmushrif()
      })
  }

  private goToEmushrif (): void {
    const redirectUrl = localStorage.getItem('redirectUrl') as string
    const caseId = localStorage.getItem('caseId') as string
    const serialNumber = localStorage.getItem('serialNumber') as string

    if (redirectUrl !== 'undefined' && serialNumber !== 'undefined' && caseId !== 'undefined') {
      window.location.href = redirectUrl + '?case_id=' + caseId + '&serial_number=' + serialNumber
    }
    this.$router.push({ name: 'welcome' })
  }
}
