import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AuthorizationOutcome } from '@/services/organization-authorization-service'
import i18n from '../i18n'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    beforeEnter: (to, from, next) => {
      const locale = to.query.locale as string
      console.log('locale', locale)
      localStorage.setItem('locale', locale)
      store.dispatch('walkthrough/setLang', locale)
      store.dispatch('video/initialize', locale)
      const supportedLocales = process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(',')
      console.log('Invalid locale', supportedLocales)
      if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE)
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale
      }
      return next({ path: locale, query: { serial_number: to.query.serial_number as string, case_id: to.query.case_id as string, redirect_url: to.query.redirect_url as string, locale: locale } })
    }
    // redirect: { name: 'splash', query: { serial_number: to.query.locale as string } }
  },
  {
    path: '/:locale',
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      localStorage.setItem('locale', locale)
      store.dispatch('walkthrough/setLang', locale)
      store.dispatch('video/initialize', locale)
      const supportedLocales = process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(',')
      if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE)
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale
      }
      return next()
    },
    component: () => import(/* webpackChunkName: "preTest" */ '../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'splash',
        component: () => import(/* webpackChunkName: "splash" */ '../views/SplashScreen.vue')
      },
      {
        path: 'test',
        name: 'test',
        component: () => import(/* webpackChunkName: "Step_1" */ '../views/Quidel/Test.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'log-in',
        name: 'log in',
        component: () => import(/* webpackChunkName: "logIn" */ '../views/LogIn.vue')
      },
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'pre-test',
        name: 'pre test',
        component: () => import(/* webpackChunkName: "preTest" */ '../views/PreTest/PreTest.vue'),
        children: [
          {
            path: 'video',
            name: 'pre test video',
            component: () => import(/* webpackChunkName: "Pre-test question video" */ '../views/PreTest/Video.vue'),
            meta: {
              advancement: 0,
              hideStepCount: true,
              requireAuth: false
            }
          },
          {
            path: '1',
            name: 'pre test 1',
            component: () => import(/* webpackChunkName: "Step_1" */ '../views/PreTest/Step1.vue'),
            meta: {
              advancement: 1,
              requireAuth: false
            }
          },
          {
            path: '2',
            name: 'pre test 2',
            component: () => import(/* webpackChunkName: "Step_2" */ '../views/PreTest/Step2.vue'),
            meta: {
              advancement: 2,
              requireAuth: false
            }
          },
          {
            path: '3',
            name: 'pre test 3',
            component: () => import(/* webpackChunkName: "Step_3" */ '../views/PreTest/Step3.vue'),
            meta: {
              advancement: 3,
              requireAuth: false
            }
          }
        ]
      },
      {
        path: 'innova',
        name: 'walkthrough',
        component: () => import(/* webpackChunkName: "quidle" */ '../views/Quidel/Quidel.vue'),
        children: [
          {
            path: '1',
            name: 'walkthrough 1',
            component: () => import(/* webpackChunkName: "Step_1" */ '../views/Quidel/Step1.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: '2',
            name: 'walkthrough 2',
            component: () => import(/* webpackChunkName: "Step_2" */ '../views/Quidel/Step2.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: '3',
            name: 'walkthrough 3',
            component: () => import(/* webpackChunkName: "Step_3" */ '../views/Quidel/Step3.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: '4',
            name: 'walkthrough 4',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/Step4.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: '5/:name?',
            name: 'walkthrough 5',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/step_5/Step5.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: '6',
            name: 'walkthrough 6',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/Step6.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: '7',
            name: 'walkthrough 7',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/Step7.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: 'qrpage',
            name: 'qrpage',
            component: () => import(/* webpackChunkName: "Step_1" */ '../views/Quidel/QrPage.vue'),
            meta: {
              requireAuth: false
            }
          },
          {
            path: 'result',
            name: 'walkthrough result',
            component: () => import(/* webpackChunkName: "Result" */ '../views/Quidel/Result.vue'),
            meta: {
              requireAuth: false
            }
          }
        ]
      },
      {
        path: 'outcome/inconclusive',
        name: 'inconclusive outcome',
        component: () => import(/* webpackChunkName: "Outcome" */ '../views/Quidel/Inconclusive.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'legal-area',
        name: 'legal-area',
        component: () => import(/* webpackChunkName: "legal" */ '../views/LegalArea.vue')
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicy.vue')
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue')
      },
      {
        path: 'rating',
        name: 'rating',
        component: () => import(/* webpackChunkName: "rating" */ '../views/Rating/Rating.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'rating/success',
        name: 'rating-success',
        component: () => import(/* webpackChunkName: "FeedBack" */ '../views/Rating/Success.vue'),
        meta: {
          requireAuth: false
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authOutcome = store.getters['auth/authorizationOutcome'] as AuthorizationOutcome
  if (to?.meta?.requireAuth && authOutcome !== AuthorizationOutcome.valid) next({ name: 'log in' })
  else next()
})

export default router
