import Vue from 'vue'
import App from './App.vue'
import './bootstrap-vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './v-mask'
import './mobile-detection'
import './assets/scss/app.scss'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueVideoPlayer from 'vue-video-player'
import ChatButtonProxy from './components/ChatButtonProxy.vue'

import 'video.js/dist/video-js.css'

Vue.prototype.$screen = new Vue({
  data: {
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
      pageHeight: window.innerHeight
    }
  }
}).screen

window.addEventListener('load', () => {
  setTimeout(function () {
    Vue.prototype.$screen.width = window.innerWidth
    Vue.prototype.$screen.height = window.innerHeight
    const boxHeader = document.querySelector('.header-wrapper')
    const heightH = (boxHeader as HTMLElement)?.offsetHeight
    const boxFooter = document.querySelector('.footer-wrapper')
    const heightF = (boxFooter as HTMLElement)?.offsetHeight
    Vue.prototype.$screen.pageHeight = Vue.prototype.$screen.height - heightH - heightF
  }, 1000)
})

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth
  Vue.prototype.$screen.height = window.innerHeight
  const boxHeader = document.querySelector('.header-wrapper')
  const heightH = (boxHeader as HTMLElement)?.offsetHeight
  const boxFooter = document.querySelector('.footer-wrapper')
  const heightF = (boxFooter as HTMLElement)?.offsetHeight
  Vue.prototype.$screen.pageHeight = Vue.prototype.$screen.height - heightH - heightF
})

Vue.use(VueVideoPlayer)
Vue.use(PerfectScrollbar)
Vue.component('ChatButtonProxy', ChatButtonProxy)
Vue.config.productionTip = false
store
  .dispatch('initialize')
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })
console.log('V:1.1.18')
