import firebaseService from './firebase-service'

export declare type VideoURLs = string[]

export interface IVideoURLsDataSource {
  getVideoURLs(lang: string): Promise<VideoURLs>
}

export class RemoteConfigVideoURLsDataSource implements IVideoURLsDataSource {
  getVideoURLs (lang: string): Promise<VideoURLs> {
    return firebaseService.getRemoteConfig().then((remoteConfig) => {
      return remoteConfig.fetchAndActivate().then(() => {
        // const parameters = remoteConfig.getAll()
        // Object.entries(parameters).forEach($ => {
        //   const [key, entry] = $
        //   console.log('Key: ', key)
        //   console.log('Source: ', entry)
        // })
        console.log('lang', lang)
        const configurationValue = remoteConfig.getString(lang === 'ar' ? 'bh_innova_oman_video_ar' : 'bh_innova_oman_video')
        return JSON.parse(configurationValue)
      })
    })
  }
}

export const instance = new RemoteConfigVideoURLsDataSource()
export default instance
