import { MutationTree } from 'vuex'
import { TimerState } from '@/store/modules/timer/index'
import { Location } from 'vue-router'

export default {
  startInfo (state, payload: { sourceLocation: Location, timeoutHandle: number | null, startedAt: number | null, durationInSeconds: number }) {
    state.sourceLocation = payload.sourceLocation
    state.startedAt = payload.startedAt
    state.durationInSeconds = payload.durationInSeconds
  },
  elapsedTimeInSeconds (state, payload: number) {
    state.elapsedTimeInSeconds = payload
  },
  timerBarEnabled (state, payload: boolean) {
    state.timerBarEnabled = payload
  },
  clearAlreadyCompletedTimers (state) {
    state.alreadyCompletedTimers = []
  },
  trackCompletedTimer (state, payload: Location) {
    state.alreadyCompletedTimers.push(payload)
  }
} as MutationTree<TimerState>
