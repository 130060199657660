import { TestResultHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/testResultHighlight'
import { SymptomsHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/symptomsHighlight'
import { ExposureHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/exposureHighlight'
import { OutcomeType } from '@/store/modules/walkthrough/types/outcomes/common/outcomeType'
import { ResultOverview } from '@/store/modules/walkthrough/types/outcomes/resultOverview/resultOverview'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { TestResult } from '@/store/modules/walkthrough/types/testResult'

export class OutcomeScenario {
  static readonly SCENARIO_1 = (symptoms: Symptoms, exposure: Exposure): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.NEGATIVE,
      new ResultOverview(
        [
          TestResultHighlight.NEGATIVE,
          SymptomsHighlight.for(symptoms),
          ExposureHighlight.for(exposure)
        ]
      )
    )
  }

  static readonly SCENARIO_2 = (symptoms: Symptoms, exposure: Exposure): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        [
          TestResultHighlight.POSITIVE,
          SymptomsHighlight.for(symptoms),
          ExposureHighlight.for(exposure)
        ]
      )
    )
  }

  // static readonly SCENARIO_3 = (): OutcomeScenario => {
  //   return new OutcomeScenario(
  //     OutcomeType.POSITIVE,
  //     new ResultOverview(
  //       [
  //         TestResultHighlight.POSITIVE,
  //         SymptomsHighlight.HAVE_SYMPTOMS,
  //         ExposureHighlight.NO_EXPOSURE
  //       ]
  //     )
  //   )
  // }

  // static readonly SCENARIO_4 = (): OutcomeScenario => {
  //   return new OutcomeScenario(
  //     OutcomeType.POSITIVE,
  //     new ResultOverview(
  //       [
  //         SymptomsHighlight.NO_SYMPTOMS,
  //         ExposureHighlight.BEEN_EXPOSED,
  //         TestResultHighlight.NEGATIVE
  //       ]
  //     )
  //   )
  // }

  static readonly SCENARIO_INCONCLUSIVE = (): OutcomeScenario => {
    return new OutcomeScenario(
      OutcomeType.INCONCLUSIVE,
      new ResultOverview(
        [
          TestResultHighlight.INCONCLUSIVE
        ]
      )
    )
  }

  // eslint-disable-next-line no-useless-constructor
  private constructor (public readonly outcomeType: OutcomeType, public readonly resultOverview: ResultOverview) {
  }

  public static getMatchingScenario (symptoms: Symptoms, exposure: Exposure, testResult: TestResult): OutcomeScenario {
    if (testResult === null) {
      throw new Error('invalid test result')
    }

    // if (testResult === TestResult.Inconclusive) {
    //   return OutcomeScenario.SCENARIO_INCONCLUSIVE()
    // } else if (exposure.any() && testResult === TestResult.Negative) {
    //   return OutcomeScenario.SCENARIO_1(symptoms, exposure)
    // } else if (exposure.none() && testResult === TestResult.Negative) {
    //   return OutcomeScenario.SCENARIO_1(symptoms, exposure)
    // } else if (symptoms.any() && testResult === TestResult.Positive) {
    //   return OutcomeScenario.SCENARIO_2(symptoms, exposure)
    // } else if (symptoms.none() && testResult === TestResult.Negative) {
    //   return OutcomeScenario.SCENARIO_2(symptoms, exposure)
    // } else {
    //   return OutcomeScenario.Custom(symptoms, exposure, testResult)
    // }

    if (testResult === TestResult.Inconclusive) {
      console.log('testResult', 1)
      return OutcomeScenario.SCENARIO_INCONCLUSIVE()
    } else if (symptoms.none() && exposure.none() && testResult === TestResult.Negative) {
      console.log('testResult', 2)
      return OutcomeScenario.SCENARIO_1(symptoms, exposure)
    } else if (symptoms.none() && exposure.any() && testResult === TestResult.Negative) {
      console.log('testResult', 3)
      return OutcomeScenario.SCENARIO_1(symptoms, exposure)
    } else if (symptoms.any() && exposure.none() && testResult === TestResult.Negative) {
      console.log('testResult', 4)
      return OutcomeScenario.SCENARIO_1(symptoms, exposure)
    } else if (symptoms.any() && exposure.any() && testResult === TestResult.Negative) {
      console.log('testResult', 5)
      return OutcomeScenario.SCENARIO_1(symptoms, exposure)
    } else if (symptoms.any() && exposure.none() && testResult === TestResult.Positive) {
      console.log('testResult', 6)
      return OutcomeScenario.SCENARIO_2(symptoms, exposure)
    } else if (symptoms.none() && exposure.none() && testResult === TestResult.Positive) {
      console.log('testResult', 7)
      return OutcomeScenario.SCENARIO_2(symptoms, exposure)
    } else if (symptoms.any() && exposure.any() && testResult === TestResult.Positive) {
      console.log('testResult', 8)
      return OutcomeScenario.SCENARIO_2(symptoms, exposure)
    } else if (symptoms.none() && exposure.any() && testResult === TestResult.Positive) {
      console.log('testResult', 9)
      return OutcomeScenario.SCENARIO_2(symptoms, exposure)
    } else {
      console.log('testResult', 10)
      return OutcomeScenario.Custom(symptoms, exposure, testResult)
    }
  }

  private static Custom (symptoms: Symptoms, exposure: Exposure, testResult: TestResult): OutcomeScenario {
    return new OutcomeScenario(
      OutcomeType.POSITIVE,
      new ResultOverview(
        [
          TestResultHighlight.for(testResult),
          SymptomsHighlight.for(symptoms),
          ExposureHighlight.for(exposure)
        ]
      )
    )
  }
}
