import { ModuleTree } from 'vuex'
import { ApplicationState } from '@/store'
import auth from './auth'
import video from './video/index'
import faq from './faq/index'
import walkthrough from './walkthrough'
import timer from './timer'

export default {
  auth,
  video,
  faq,
  walkthrough,
  timer
} as ModuleTree<ApplicationState>
