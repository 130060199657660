





















import { Component, Vue } from 'vue-property-decorator'
import Logo from '@/components/Logo.vue'
import MobileNav from '@/components/MobileNav.vue'
import store from '@/store'
@Component({
  components: {
    Logo,
    MobileNav
  }
})
export default class Header extends Vue {
  startTheTestButtonVisible = false;
  bellaMissionButtonVisible = false;
  private isMobileNav = false
  created (): void {
    store.watch(
      (state, getters) => getters['auth/hasLoggedIn'],
      (hasLoggedIn): void => {
        this.startTheTestButtonVisible = hasLoggedIn
        this.bellaMissionButtonVisible = hasLoggedIn
      }
    )
  }
}
