import firebaseService from './firebase-service'

export declare type Faq = any

export interface IFaqDataSource {
  getFaqData(): Promise<Faq>
}

export class RemoteConfigFaqDataSource implements IFaqDataSource {
  getFaqData (): Promise<Faq> {
    return firebaseService.getRemoteConfig().then((remoteConfig) => {
      return remoteConfig.fetchAndActivate().then(() => {
        let configurationValue = ''
        const locale = localStorage.getItem('locale') as string
        if (locale === 'ar') {
          configurationValue = remoteConfig.getString('bh_faq_ar')
          // eslint-disable-next-line no-eval
          configurationValue = eval(configurationValue)
        } else {
          configurationValue = remoteConfig.getString('BH_faq_web')
          configurationValue = JSON.parse(configurationValue)
        }
        return configurationValue
      })
    })
  }
}

export const instance = new RemoteConfigFaqDataSource()
export default instance
