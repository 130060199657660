import { GetterTree } from 'vuex'
import { TimerState } from '@/store/modules/timer/index'
import { ApplicationState } from '@/store'
import { RawLocation } from 'vue-router'

export default {
  durationInSeconds: (state) => {
    return state.durationInSeconds
  },
  started: (state): boolean => {
    return state.startedAt !== null
  },
  elapsedTimeInSeconds: (state): number | null => {
    return state.elapsedTimeInSeconds
  },
  running: (state, { started, finished }): boolean => {
    return started && !finished
  },
  sourceLocation: (state): RawLocation | null => {
    return state.sourceLocation
  },
  finished: (state, { timeRemainingInSeconds }): boolean | null => {
    if (timeRemainingInSeconds === null) return null

    return timeRemainingInSeconds === 0
  },
  timeRemainingInSeconds: (state, { durationInSeconds, started, elapsedTimeInSeconds }): number | null => {
    if (!started || !elapsedTimeInSeconds) return null

    const remainingTimeInSeconds = durationInSeconds - elapsedTimeInSeconds
    return Math.max(0, remainingTimeInSeconds)
  },
  timeRemainingInPercentage: (
    state,
    { durationInSeconds, timeRemainingInSeconds }
  ): number | null => {
    if (timeRemainingInSeconds === null) return null

    return (timeRemainingInSeconds / durationInSeconds) * 100
  },
  timeRemainingInHumanReadableString: (
    state,
    { timeRemainingInSeconds }
  ): string | null => {
    if (timeRemainingInSeconds === null) return null

    const minutes = Math.trunc(timeRemainingInSeconds / 60).toString().padStart(2, '0')
    const seconds = Math.trunc(timeRemainingInSeconds % 60).toString().padStart(2, '0')

    return `${minutes}:${seconds}`
  },
  timerBarVisible: (state, { started }): boolean => {
    return started && state.timerBarEnabled
  }
} as GetterTree<TimerState, ApplicationState>
