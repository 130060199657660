import { GetterTree } from 'vuex'
import assetLoader from '@/utils/asset-loader'
import { ApplicationState } from '@/store'
import { WalkthroughState } from '@/store/modules/walkthrough/index'
import {
  TestResultOption
} from '@/store/modules/walkthrough/types/types'
import { TestResult } from '@/store/modules/walkthrough/types/testResult'
import { SprayState } from '@/store/modules/walkthrough/types/pre-test/sprayState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { OutcomeScenario } from '@/store/modules/walkthrough/types/outcomes/outcomeScenario'

export default {
  testResultOptions: (): TestResultOption[] => {
    return [
      {
        result: TestResult.Positive,
        iconSrc: assetLoader.load('input-result-options/result-oman-01.svg')
      },
      {
        result: TestResult.Positive,
        iconSrc: assetLoader.load('input-result-options/result-oman-02.svg')
      },
      {
        result: TestResult.Negative,
        iconSrc: assetLoader.load('input-result-options/result-oman-03.svg')
      },
      {
        result: TestResult.Inconclusive,
        iconSrc: assetLoader.load('input-result-options/result-oman-04.svg')
      },
      {
        result: TestResult.Inconclusive,
        iconSrc: assetLoader.load('input-result-options/result-oman-05.svg')
      }
    ]
  },

  selectedOption: (state): TestResultOption | null => {
    return state.selected_option
  },

  getLang: (state): string => {
    return state.lang
  },

  sprayState: (state): SprayState | null => {
    return state.spray_state
  },

  symptoms: (state): Symptoms | null => {
    return state.symptoms
  },

  haveSymptoms: (state, getters): boolean | null => {
    let haveSymptoms = false
    const symptoms = getters.symptoms
    for (const prop in symptoms) {
      if (!Object.prototype.hasOwnProperty.call(symptoms, prop)) {
        continue
      }
      haveSymptoms = haveSymptoms || !!symptoms[prop]
    }
    return haveSymptoms
  },

  exposure: (state): Exposure | null => {
    return state.exposure
  },

  beenExposed: (state, getters): boolean | null => {
    let haveBeenExposed = false
    const exposure = getters.exposure
    for (const prop in exposure) {
      if (!Object.prototype.hasOwnProperty.call(exposure, prop)) {
        continue
      }
      haveBeenExposed = haveBeenExposed || !!exposure[prop]
    }
    return haveBeenExposed
  },

  submitResultTimedOut: (state): boolean => {
    return !!state.submit_result_timed_out
  },

  outcomeScenario: (state, getters): OutcomeScenario | null => {
    const selectedOption = getters.selectedOption
    if (selectedOption == null) return null
    const testResult = selectedOption.result

    const sprayState = getters.sprayState
    if (sprayState == null) return null

    const symptoms = getters.symptoms
    if (symptoms == null) return null

    const exposure = getters.exposure
    if (exposure == null) return null

    return OutcomeScenario.getMatchingScenario(symptoms, exposure, testResult)
  },

  testCompletionTime: (state): Date | null => {
    return state.test_completion_time
  },

  testResultsExpirationTime: (state): Date | null => {
    return state.test_validity_expiration_time
  }
} as GetterTree<WalkthroughState, ApplicationState>
