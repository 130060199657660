import { MutationTree } from 'vuex'
import { WalkthroughState } from '@/store/modules/walkthrough/index'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import { SprayState } from '@/store/modules/walkthrough/types/pre-test/sprayState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'

export default {

  sprayState (state, payload: SprayState) {
    state.spray_state = payload
  },

  symptoms (state, payload: Symptoms) {
    state.symptoms = payload
  },

  setLang (state, payload: string) {
    state.lang = payload
  },

  exposure (state, payload: Exposure) {
    state.exposure = payload
  },

  selectedOption (state, payload: TestResultOption) {
    state.selected_option = payload
  },

  testCompletionTime (state, payload: Date) {
    state.test_completion_time = payload
  },

  testValidityExpirationTime (state, payload: Date) {
    state.test_validity_expiration_time = payload
  },

  submitResultTimedOut (state, payload: boolean | null) {
    state.submit_result_timed_out = payload
  },

  submitResultCountdownHandle (state, payload: number | null) {
    state.submit_result_countdown_handle = payload
  }

} as MutationTree<WalkthroughState>
