import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import { AuthorizationOutcome } from '@/services/organization-authorization-service'

import mutations from './mutations'
import actions from './actions'
import getters from '@/store/modules/auth/getters'

export interface AuthState {
  userId: string | null,
  userAuthToken: string | null,
  organizationCode: string;
  authorizationIsOngoing: boolean;
  authorizationError: Error | null;
  authorizationOutcome: AuthorizationOutcome;
}

export const initialState: AuthState = {
  userId: null,
  userAuthToken: null,
  organizationCode: '',
  authorizationIsOngoing: false,
  authorizationError: null,
  authorizationOutcome: AuthorizationOutcome.unknown
}

export function getInitialState (): AuthState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState,
  mutations,
  actions,
  getters
} as Module<AuthState, ApplicationState>
