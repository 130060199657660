import assetLoader from '@/utils/asset-loader'

export class OutcomeType {
  public static POSITIVE = new OutcomeType(
    assetLoader.load('result/positive-logo.svg')
  )

  public static NEGATIVE = new OutcomeType(
    assetLoader.load('result/negative-logo.svg')
  )

  public static INCONCLUSIVE = new OutcomeType(
    assetLoader.load('result/inconclusive-logo.svg')
  )

  // eslint-disable-next-line no-useless-constructor
  private constructor (
    public readonly resultPageLogoSrc: string
  ) {}
}
