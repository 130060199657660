






























import { Vue } from 'vue-property-decorator'

interface ILinks {
  icon: string
  path: string
  text: string
}
export default class Header extends Vue {
  private navLinks = [
    {
      icon: require('../assets/images/header/icon-20-privacy.svg'),
      path: this.$t('footer.links[0].path'),
      text: this.$t('footer.links[0].label')
    },
    {
      icon: require('../assets/images/header/icon-20-terms.svg'),
      path: this.$t('footer.links[1].path'),
      text: this.$t('footer.links[1].label')
    }
  ]

  private navLinkPushState = [
    {
      icon: require('../assets/images/header/icon-20-faq-mobile.svg'),
      path: '/faq',
      text: this.$t('header.faq_link.text')
    }
  ]

  private goTo (item: ILinks): void {
    this.$emit('close-nav')
    this.$router.push(item.path)
  }
}
