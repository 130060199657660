import { ActionTree } from 'vuex'
import { AuthState } from '@/store/modules/auth/index'
import { ApplicationState } from '@/store'
import organizationAuthorizationService, { AuthorizationOutcome } from '@/services/organization-authorization-service'
import authenticationService from '@/services/authentication-service'

export default {
  async init (context): Promise<void> {
    const authResponse = await authenticationService.authenticate()
    if (!authResponse) {
      return
    }
    context.commit('userId', authResponse.userId)
  },
  setUpdatedOrganizationCode (context, organizationCode): void {
    context.commit('organizationCode', organizationCode)
    context.commit('authorizationIsOngoing', false)
    context.commit('authorizationOutcome', null)
    context.commit('authorizationError', null)
  },
  async authorize (context): Promise<void> {
    context.commit('authorizationIsOngoing', true)

    try {
      const authorizationOutcome = await organizationAuthorizationService
        .authorize(context.state.organizationCode)

      context.commit('authorizationOutcome', authorizationOutcome)
    } catch (error) {
      context.commit('authorizationOutcome', AuthorizationOutcome.error)
      context.commit('authorizationError', error)
    } finally {
      context.commit('authorizationIsOngoing', false)
    }
  }
} as ActionTree<AuthState, ApplicationState>
