import axios from 'axios'
import { SprayState } from '@/store/modules/walkthrough/types/pre-test/sprayState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import { TestResult } from '@/store/modules/walkthrough/types/testResult'
import authenticationService from '@/services/authentication-service'

export interface StatisticalDataService {
  send(userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, sprayState: SprayState, symptoms: Symptoms, exposure: Exposure, testResult: TestResultOption): Promise<void>
}

export class FirebaseStatisticalDataService implements StatisticalDataService {
  async send (userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, sprayState: SprayState, symptoms: Symptoms, exposure: Exposure, testResult: TestResultOption): Promise<void> {
    if (!process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      throw new Error('No base url specified for firebase functions')
    }

    try {
      let organizationCode = '2027-0394-8833-2433'
      if (window.location.host.includes('om.innova.bella.health')) {
        organizationCode = '1027-0394-8833-2433'
      }
      const token = await authenticationService.getToken()
      const payload = buildPayload(userId, organizationCode, dateCompleted, validUntil, sprayState, symptoms, exposure, testResult)
      const response = await axios
        .post<unknown>(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/organizations/${organizationCode}/submit`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            'User-Agent': 'web',
            'Accept-Language': localStorage.getItem('locale')
          }
        })
    } catch (error) {
      console.error('[FirebaseStatisticalDataService] send failed', error)
    }
  }
}

function mapToSprayStateStringValue (sprayState: SprayState): string {
  return sprayState === SprayState.YesUsed
    ? 'YesUsed'
    : 'NotUsed'
}

function buildPayload (userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, sprayState:SprayState, symptoms: Symptoms, exposure: Exposure, testResult: TestResultOption) {
  const result = localStorage.getItem('stripResult') as string
  return {
    health_check: {
      symptoms_fever: symptoms.fever,
      symptoms_respiratory: symptoms.respiratorySigns,
      symptoms_fatigue: symptoms.headaches,
      symptoms_sensory: symptoms.lossOfSmell,
      exposure_positive: exposure.have,
      exposure_symptoms: exposure.haveSymptoms
    },
    test: {
      cartridge_serial_number: localStorage.getItem('serialNumber'),
      cartridge_verified: Boolean(localStorage.getItem('cartridge_verified')),
      case_id: localStorage.getItem('caseId'),
      test_completed_at: dateCompleted,
      test_result: parseInt(result),
      test_image: localStorage.getItem('takenPicture')
    }
  }
}

function mapToResultTypeStringValue (result: TestResult): number {
  switch (result) {
    case TestResult.Positive:
      return 1 // 'POSITIVE'
    case TestResult.Negative:
      return 2 // 'NEGATIVE'
    case TestResult.Inconclusive:
      return 3// 'INCONCLUSIVE'
  }
}

export const instance = new FirebaseStatisticalDataService()

export default instance
