import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { Location } from 'vue-router'

export interface TimerState {
  alreadyCompletedTimers: Array<Location>;
  sourceLocation: Location | null;
  durationInSeconds: number | null;
  timeoutHandle: number | null;
  startedAt: number | null;
  elapsedTimeInSeconds: number | null;
  timerBarEnabled: boolean;
}

export const initialState: TimerState = {
  alreadyCompletedTimers: [],
  sourceLocation: null,
  durationInSeconds: null,
  timeoutHandle: null,
  startedAt: null,
  elapsedTimeInSeconds: null,
  timerBarEnabled: false
}

export function getInitialState (): TimerState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  actions,
  mutations
} as Module<TimerState, ApplicationState>
